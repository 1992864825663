.title {
    max-width: fit-content;
    position: relative;
    font-size: 34px;
    font-weight: 900;
    margin-right: auto !important;
    margin-left: auto !important;
  
    @media (max-width: 48em) {
      font-size: 24px;
    }

    
    @media (max-width: 36em) {
      font-size: 22px !important;
    }

    
    &::after {
        content: '';
        display: block;
        background-color: #f78814;
        opacity: .6;
        width: 100%;
        height: 6px;
        margin-left: auto;
        margin-right: auto;
      }
}
  
  .description {
    margin: auto;
  
    font-weight: 900;

    @media (max-width: 36em) {
      font-size: 16px !important;
    }
  }
  
  .card {
    border: 1px solid light-dark(var(--mantine-color-gray-1), var(--mantine-color-dark-5));
  }
  
  .cardTitle {
    &::after {
      content: '';
      display: block;
      background-color: #f78814;
      width: 45px;
      height: 2px;
      margin-top: var(--mantine-spacing-sm);
    }
  }