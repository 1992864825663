.footer {
    margin-top: 50px;
    padding-top: calc(var(--mantine-spacing-xl) * 2);
    padding-bottom: calc(var(--mantine-spacing-xl) * 2);
    /* background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-6)); */
    background-color: #fcfcfc;
    border-top: 1px solid light-dark(var(--mantine-color-gray-2), var(--mantine-color-dark-5));
    @media (max-width: 48em) {
      padding-top: var(--mantine-spacing-xl);
      padding-bottom: var(--mantine-spacing-xl);
    }
  }
  
  .logo {
    max-width: 200px;
  
    @media (max-width: 48em) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  
  .description {
    margin-top: 5px;
  
    @media (max-width: 48em) {
      margin-top: var(--mantine-spacing-xs);
      text-align: center;
    }
  }
  
  .inner {
    display: flex;
    justify-content: space-between;
  
    @media (max-width: 48em) {
      flex-direction: column-reverse;
      align-items: center;
    }
  }
  
  .groups {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  
    @media (max-width: 48em) {
      flex-direction: row;
      margin-bottom: 20px;
    }
  }
  
  .wrapper {
    width: 220px;
  }
  
  .link {
    display: block;
    color: light-dark(var(--mantine-color-gray-6), var(--mantine-color-dark-1));
    font-size: var(--mantine-font-size-sm);
    padding-top: 3px;
    padding-bottom: 3px;
    cursor: pointer;
  
    &:hover {
      text-decoration: underline;
    }
  }
  
  .title {
    font-size: var(--mantine-font-size-lg);
    margin-bottom: 20px;
    color: var(--mantine-color-dark-4);
  }
  
  .social {
    @media (max-width: 48em) {
      margin-top: var(--mantine-spacing-xs);
    }
  }