  .header {
    background-color: var(--mantine-color-body);
    box-shadow: 0px 1px 11px 0px rgb(0 0 0/15%);
    padding-bottom: 10px;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 100;
    @media (max-width: 36em) {
      padding-bottom: 0px;
    }
  }
  
  .inner {
    height: 75px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }
  
  .link {
    display: block;
    line-height: 1;
    padding: 15px 12px;
    text-decoration: none;
    color: light-dark(var(--mantine-color-gray-7), var(--mantine-color-dark-0));
    font-size: var(--mantine-font-size-md);
    font-weight: 700;

    border-top: 1px solid var(--mantine-color-gray-4);

    &:last-of-type {
      border-bottom: 1px solid var(--mantine-color-gray-4);
      @media (min-width: 36em) {
        border-bottom: none;
      }
    }
  
    @mixin hover {
      background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-6));
    }
  
    [data-mantine-color-scheme] &[data-active] {
      color: #f78814;
    }

    @media (min-width: 36em) {
      border-top: none;
    }
  }


  .inputSearch input:focus {
    border-color: #000 !important;
  }