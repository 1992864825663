.root {
    padding-top: 110px;
}

.inner {
    position: relative;
    background-color: #efefef;
    min-height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: min-content;

    
    @media (max-width: 36em) {
        min-height: 100%;
    }
}

.boxSearch {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    /* background-color: #00000080; */
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.boxSearchInner {
    max-width: 700px;
    padding: 50px 30px;
    @media (max-width: 36em) {
        padding: 30px 20px 5px;
    }
}