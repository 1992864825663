.boxBrand {
    border: 1px solid var(--mantine-color-gray-6);
    min-height: 200px;
    cursor: pointer;
    transition: all 5s;
}

.boxBrand:hover > img {
    transition: all 1s;
    transform: scale(1.2);
}