.card {
    background-color: light-dark(var(--mantine-color-white), var(--mantine-color-dark-7));
    height: 100%;
}

.card:hover {
    border-color: #f78814;
}

.section {
    border-bottom: 1px solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
    padding-left: var(--mantine-spacing-md);
    padding-right: var(--mantine-spacing-md);
    padding-bottom: var(--mantine-spacing-md);
    @media (max-width: 36em) {
        padding-bottom: var(--mantine-spacing-xs);
    }
}

.link {
    transition: all .3s;
    @media (max-width: 36em) {
        font-size: 16px !important;
    }
}

.link:hover {
    color: #f78814 !important;
}

.label {
    text-transform: uppercase;
    font-size: var(--mantine-font-size-xs);
    font-weight: 700;
    @media (max-width: 36em) {
        font-size: 14px !important;
    }
}

.badge {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10;
}

.card:hover a[id=btn] {
    background-color: var(--mantine-color-red-7) !important;
}